import React, { Suspense, useState } from "react";
import routes from "../../routes";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { MenuOutlined } from "@mui/icons-material";
import NewsComponent from "./NewsComponent";
import SidebarComponent from "./SidebarComponent";
import { Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import useLayoutFunctions from "../Layout_Functions";
import { useSelector } from "react-redux";

const MainLayout = ({ /*user = {},*/ open = false, setOpen = () => {} }) => {
  const { DRAWER_WIDTH_CLOSE, DRAWER_WIDTH_OPEN, correctRouter, useStyles } =
    useLayoutFunctions();
  const classes = useStyles();

  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    };
  });

  const handleOpen = (value) => {
    setOpen(value);
    //save property
    //api_remote.save_user_configuration({ config: { left_bar_open: value } });
  };

  return (
    <Stack width={"100%"}>
      <div
        //item
        //xs={12}
        style={{ backgroundColor: "#4639CA", height: "10vh" }}
        width={"100%"}
        //position={"fixed"}
      >
        <Box mt={3} mb={3} ml={1} width={"100%"}>
          <Grid container direction={"row"}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems={"center"}
              xs={7}
            >
              <Stack
                direction={"row"}
                justifyContent="flex-start"
                alignItems={"center"}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {
                    console.log("changing", open);
                    handleOpen(!open);
                  }}
                  sx={{ mr: 1 }}
                >
                  <MenuOutlined sx={{ color: "#FFFFFF" }} />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  <img
                    style={{
                      display: "inline",
                      maxWidth: "10%",
                      margin: "auto",
                    }}
                    alt="allelica"
                    src="../logo_white_nopayoff.png"
                  />
                </Typography>
              </Stack>
            </Grid>
            {user ? (
              <Grid
                container
                alignItems={"center"}
                justifyContent="flex-end"
                direction="row"
                xs={5}
              >
                <label className={classes.label}>{user && user.user}</label>
                <br />
                <label
                  className={classes.label}
                  style={{ marginLeft: 4, marginRight: 4 }}
                >
                  ({user.organization})
                </label>
                <NewsComponent />
              </Grid>
            ) : (
              <div />
            )}
          </Grid>
        </Box>
      </div>
      <Divider sx={{ backgroundColor: "#FFFFFF", mt: 0 }} />
      <div style={{ height: "89vh" }}>
        <Stack direction={"row"} height={"100%"}>
          <div
            style={{
              maxWidth: open ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE,
            }}

            //maxWidth={"30px"}
            //xs={2}
          >
            <SidebarComponent open={open} />
          </div>
          <div
            style={{
              minWidth: 0,
              width: open
                ? `calc(100% - ${DRAWER_WIDTH_OPEN + 20}px)`
                : `calc(100% - ${DRAWER_WIDTH_CLOSE + 20}px)`,
              height: "90vh",
              position: "fixed",
              marginLeft: open ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE,
              //overflowY: "auto",
            }}
          >
            <div
              style={{
                overflowY: "auto",
                height: "100vh",
              }}
            >
              <Suspense>
                <Routes>
                  {routes.map((route, idx) => {
                    return (
                      route.element && (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          element={<route.element />}
                        />
                      )
                    );
                  })}
                  {user && user.permission_list ? (
                    correctRouter(user)
                  ) : (
                    <Route path="/" />
                  )}
                </Routes>
              </Suspense>
            </div>
          </div>
        </Stack>
      </div>
    </Stack>
  );
};

export default MainLayout;

MainLayout.propTypes = {
  //user: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

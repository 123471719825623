import {
  REGISTER_NEW_TEST,
  REGISTER_NEW_TEST_CSV,
  SEARCH_REGISTERED_TEST,
  UPLOAD_PATIENT_CONSENT,
  GET_PATIENT_REPORT,
  GET_PATIENT_CONSENT,
  GET_TRACKING_INFO,
  GET_PATIENT_INFO,
  GET_USER_INFO,
  ASSIGN_BARCODE_PATIENT,
  DEALLOCATE_BARCODE_PATIENT,
  DELETE_PATIENT_CONSENT,
  SEARCH_USERS,
  GET_SITE,
  SAVE_USER,
  DELETE_USER,
  SEARCH_ORGANIZATION,
  SAVE_ORGANIZATION,
  DELETE_ORGANIZATION,
  UPLOAD_TEST_PATIENT_REPORT,
  DELETE_PATIENT_REPORT,
  ADD_NEW_PRS,
  GET_LIST_PRS,
  GET_CUSTOMER_PRS,
  DELETE_PRS,
  ADD_NEW_SHIPPING,
  DELETE_SHIPPING,
  GET_CUSTOMER_SHIPPING,
  GET_LIST_SHIPPING,
  ADD_NEW_MANDATORY_FIELD,
  DELETE_MANDATORY_FIELD,
  GET_LIST_MANDATORY_FIELD,
  GET_CUSTOMER_BLOOD_LIST,
  DELETE_REPORT_LANGUAGE,
  ADD_NEW_REPORT_LANGUAGE,
  GET_LIST_REPORT_LANGUAGE,
  GET_CUSTOMER_REPORT_LANGUAGE,
  UPDATE_TRACKING_INFO_CSV,
  DELETE_SINGLE_TRACKING_INFO,
  PATIENT_DETAILS,
  UPDATE_SINGLE_TRACKING_INFO,
  INSERT_BARCODE,
  DELETE_BARCODE,
  LIST_BARCODES,
  DELETE_TEST,
  MODIFY_PRS_LIST_TEST,
  INSERT_MANY_BARCODES,
  ADD_NEW_EXTRA_MODEL,
  DELETE_EXTRA_MODEL,
  GET_LIST_EXTRA_MODELS,
  GET_CUSTOMER_EXTRA_MODELS,
  SEND_PAYMENT_EMAIL,
  SEARCH_REGISTERED_TEST_ADMIN,
  RESET_USER_PSW,
  SAVE_USER_CONFIG,
  GET_ALL_SENT_EMAIL,
  PAYMENT_ALREADY_DONE,
  DELETE_PAYMENT_REQUEST,
  GET_GENERAL_REPORTS_CREATED_DONE_STATISTICS,
  GET_ORGANIZATION_FREE_TEST_USAGE,
} from "./constants";

import useAxiosConfig from "./useAxiosConfig";

const useApi = () => {
  const { axiosInstance, isLoading } = useAxiosConfig();

  const get_user_info = async () => {
    const { url, method } = GET_USER_INFO;
    const result = await axiosInstance[method](url);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const save_user_configuration = async (datas, funct) => {
    const { url, method } = SAVE_USER_CONFIG;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const register_new_test = async (datas) => {
    const { url, method } = REGISTER_NEW_TEST;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_test = async (datas) => {
    const { url, method } = DELETE_TEST;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const modify_prs_list_test = async (datas) => {
    const { url, method } = MODIFY_PRS_LIST_TEST;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const register_new_test_csv = async (datas) => {
    const { url, method } = REGISTER_NEW_TEST_CSV;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const search_registered_test = async (params) => {
    //orderId
    const { url, method } = SEARCH_REGISTERED_TEST;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const search_registered_test_admin = async (params) => {
    //orderId
    const { url, method } = SEARCH_REGISTERED_TEST_ADMIN;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const upload_patient_consent = async (datas) => {
    const { url, method } = UPLOAD_PATIENT_CONSENT;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return [];
  };

  const upload_test_patient_report = async (datas) => {
    const { url, method } = UPLOAD_TEST_PATIENT_REPORT;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return [];
  };

  const get_patient_report = async (params) => {
    //orderId
    const { url, method } = GET_PATIENT_REPORT;
    const result = await axiosInstance[method](url, {
      params: params,
      responseType: "blob",
    });
    if (result && result.data) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "report_" + params.orderId + "_" + params.language + "." + params.type
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    }

    return result;
  };

  const get_patient_consent = async (params) => {
    const { url, method } = GET_PATIENT_CONSENT;
    const result = await axiosInstance[method](url, {
      params: params,
      responseType: "blob",
    });
    if (result && result.data) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "consent_" + params.orderId + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }

    return { state: "error" };
  };

  const get_tracking_info = async (params) => {
    //orderId
    const { url, method } = GET_TRACKING_INFO;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;

      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return {};
  };

  const get_patient_info = async (params) => {
    //patientId
    const { url, method } = GET_PATIENT_INFO;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const patient_details = async (params, funct) => {
    //patientId
    const { url, method } = PATIENT_DETAILS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const assign_barcode_patient = async (datas) => {
    const { url, method } = ASSIGN_BARCODE_PATIENT;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const deallocate_barcode_patient = async (datas) => {
    const { url, method } = DEALLOCATE_BARCODE_PATIENT;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const insert_barcode = async (datas) => {
    const { url, method } = INSERT_BARCODE;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const insert_many_barcodes = async (datas) => {
    const { url, method } = INSERT_MANY_BARCODES;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_barcode = async (datas) => {
    const { url, method } = DELETE_BARCODE;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_list_barcodes = async (params) => {
    //orderId
    const { url, method } = LIST_BARCODES;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;

      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return {};
  };

  const delete_patient_consent = async (datas) => {
    const { url, method } = DELETE_PATIENT_CONSENT;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_patient_report = async (datas) => {
    const { url, method } = DELETE_PATIENT_REPORT;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_site = async (params) => {
    //orderId
    const { url, method } = GET_SITE;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      return result.data;
    }

    return result;
  };

  const search_users = async (params) => {
    //orderId
    const { url, method } = SEARCH_USERS;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  const save_user = async (datas) => {
    const { url, method } = SAVE_USER;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_user = async (datas) => {
    const { url, method } = DELETE_USER;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const reset_user_password = async (datas) => {
    const { url, method } = RESET_USER_PSW;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const search_organizations = async (params) => {
    //orderId
    const { url, method } = SEARCH_ORGANIZATION;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });

      return keyed_items;
    }

    return result;
  };

  const save_organization = async (datas) => {
    const { url, method } = SAVE_ORGANIZATION;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_organization = async (datas) => {
    const { url, method } = DELETE_ORGANIZATION;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  /** PRS MANAGEMENT */

  const add_new_prs = async (datas) => {
    const { url, method } = ADD_NEW_PRS;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_prs = async (datas) => {
    const { url, method } = DELETE_PRS;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_list_prs = async (params) => {
    const { url, method } = GET_LIST_PRS;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  const send_payment_email = async (datas) => {
    const { url, method } = SEND_PAYMENT_EMAIL;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const payment_already_done = async (datas) => {
    const { url, method } = PAYMENT_ALREADY_DONE;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_payment_request = async (datas) => {
    const { url, method } = DELETE_PAYMENT_REQUEST;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_customer_prs = async (params) => {
    const { url, method } = GET_CUSTOMER_PRS;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  /** EXTRA MODELS MANAGEMENT */

  const add_new_extra_model = async (datas) => {
    const { url, method } = ADD_NEW_EXTRA_MODEL;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_extra_model = async (datas) => {
    const { url, method } = DELETE_EXTRA_MODEL;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_list_extra_models = async (params) => {
    const { url, method } = GET_LIST_EXTRA_MODELS;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  const get_customer_extra_models = async (params) => {
    const { url, method } = GET_CUSTOMER_EXTRA_MODELS;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  /** SHIPPING MANAGEMENT */
  const add_new_shipping = async (datas) => {
    const { url, method } = ADD_NEW_SHIPPING;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_shipping = async (datas) => {
    const { url, method } = DELETE_SHIPPING;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_list_shipping = async (params) => {
    const { url, method } = GET_LIST_SHIPPING;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  const get_customer_shipping = async (params) => {
    const { url, method } = GET_CUSTOMER_SHIPPING;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  /** SPECIMENTs MANAGEMENT */

  const add_new_mandatory_field = async (datas) => {
    const { url, method } = ADD_NEW_MANDATORY_FIELD;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_mandatory_field = async (datas) => {
    const { url, method } = DELETE_MANDATORY_FIELD;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_list_mandatory_field = async (params) => {
    const { url, method } = GET_LIST_MANDATORY_FIELD;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  const get_customer_blood_list = async (params) => {
    const { url, method } = GET_CUSTOMER_BLOOD_LIST;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  const get_customer_saliva_list = async (params) => {
    const { url, method } = GET_CUSTOMER_SALIVA_LIST;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  /** PRS MANAGEMENT */

  const add_new_report_language = async (datas) => {
    const { url, method } = ADD_NEW_REPORT_LANGUAGE;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const delete_report_language = async (datas) => {
    const { url, method } = DELETE_REPORT_LANGUAGE;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_list_report_language = async (params) => {
    const { url, method } = GET_LIST_REPORT_LANGUAGE;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  const get_customer_report_language = async (params) => {
    const { url, method } = GET_CUSTOMER_REPORT_LANGUAGE;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return result;
  };

  //tracking

  const update_tracking_info_csv = async (datas) => {
    const { url, method } = UPDATE_TRACKING_INFO_CSV;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const delete_single_tracking_info = async (datas) => {
    const { url, method } = DELETE_SINGLE_TRACKING_INFO;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const update_single_tracking_info = async (datas) => {
    const { url, method } = UPDATE_SINGLE_TRACKING_INFO;
    const result = await axiosInstance[method](url, datas);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const get_all_sent_email = async (params) => {
    //orderId
    const { url, method } = GET_ALL_SENT_EMAIL;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const get_all_statistics = async (params) => {
    //orderId
    const { url, method } = GET_GENERAL_REPORTS_CREATED_DONE_STATISTICS;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const get_organization_free_test_usage = async (params) => {
    //orderId
    const { url, method } = GET_ORGANIZATION_FREE_TEST_USAGE;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const item = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.

      return item;
    }

    return {};
  };

  return {
    register_new_test,
    save_user_configuration,
    delete_test,
    modify_prs_list_test,
    register_new_test_csv,
    search_registered_test,
    search_registered_test_admin,
    upload_patient_consent,
    upload_test_patient_report,
    get_patient_consent,
    get_patient_info,
    get_patient_report,
    get_tracking_info,
    get_user_info,
    patient_details,
    assign_barcode_patient,
    deallocate_barcode_patient,
    insert_barcode,
    insert_many_barcodes,
    delete_barcode,
    get_list_barcodes,
    delete_patient_consent,
    delete_patient_report,
    search_users,
    get_site,
    save_user,
    delete_user,
    reset_user_password,
    search_organizations,
    save_organization,
    delete_organization,
    add_new_prs,
    get_list_prs,
    get_customer_prs,
    delete_prs,
    add_new_extra_model,
    get_list_extra_models,
    get_customer_extra_models,
    delete_extra_model,
    get_list_shipping,
    get_customer_shipping,
    delete_shipping,
    add_new_shipping,
    get_list_mandatory_field,
    add_new_mandatory_field,
    delete_mandatory_field,
    get_customer_saliva_list,
    get_customer_blood_list,
    delete_report_language,
    add_new_report_language,
    get_customer_report_language,
    get_list_report_language,
    update_tracking_info_csv,
    delete_single_tracking_info,
    update_single_tracking_info,
    send_payment_email,
    get_all_sent_email,
    payment_already_done,
    delete_payment_request,
    get_all_statistics,
    get_organization_free_test_usage,
    isLoading,
  };
};

export default useApi;

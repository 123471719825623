const BASIC_REST = "";
const BASIC_ADMIN = "/admin";
const GET = "get";
const POST = "post";

const REGISTER_NEW_TEST = {
  url: BASIC_REST + "/registerNewTest",
  method: POST,
};

const DELETE_TEST = {
  url: BASIC_ADMIN + "/deleteTest",
  method: POST,
};

const MODIFY_PRS_LIST_TEST = {
  url: BASIC_ADMIN + "/modifyPRSListTest",
  method: POST,
};

const REGISTER_NEW_TEST_CSV = {
  url: BASIC_REST + "/registerTestCsv",
  method: POST,
};

const SEARCH_REGISTERED_TEST = {
  url: BASIC_REST + "/searchRegisteredTest",
  method: GET,
};

const SEARCH_REGISTERED_TEST_ADMIN = {
  url: BASIC_ADMIN + "/searchRegisteredTest",
  method: GET,
};

const UPLOAD_PATIENT_CONSENT = {
  url: BASIC_REST + "/uploadTestPatientConsent",
  method: POST,
};
const UPLOAD_TEST_PATIENT_REPORT = {
  url: BASIC_ADMIN + "/uploadTestPatientReport",
  method: POST,
};

const GET_PATIENT_REPORT = {
  url: BASIC_REST + "/getTestPatientReport",
  method: GET,
};
const GET_PATIENT_CONSENT = {
  url: BASIC_REST + "/getTestPatientConsent",
  method: GET,
};

const GET_TRACKING_INFO = {
  url: BASIC_REST + "/searchTrackingTest",
  method: GET,
};

const GET_PATIENT_INFO = {
  url: BASIC_REST + "/getPatientInfo",
  method: GET,
};

const PATIENT_DETAILS = {
  url: BASIC_REST + "/patientDetails",
  method: GET,
};

const GET_USER_INFO = {
  url: BASIC_REST + "/getUserInfo",
  method: GET,
};

const SAVE_USER_CONFIG = {
  url: BASIC_REST + "/saveUserConfig",
  method: POST,
};

const ASSIGN_BARCODE_PATIENT = {
  url: BASIC_REST + "/assignBarcode",
  method: POST,
};

const DEALLOCATE_BARCODE_PATIENT = {
  url: BASIC_REST + "/deallocateBarcode",
  method: POST,
};

const INSERT_BARCODE = {
  url: BASIC_ADMIN + "/insertBarcode",
  method: POST,
};

const INSERT_MANY_BARCODES = {
  url: BASIC_ADMIN + "/insertManyBarcodes",
  method: POST,
};

const DELETE_BARCODE = {
  url: BASIC_ADMIN + "/deleteBarcode",
  method: POST,
};

const LIST_BARCODES = {
  url: BASIC_ADMIN + "/listBarcodes",
  method: GET,
};

const DELETE_PATIENT_CONSENT = {
  url: BASIC_REST + "/deleteTestPatientConsent",
  method: POST,
};

const DELETE_PATIENT_REPORT = {
  url: BASIC_ADMIN + "/deleteTestPatientReport",
  method: POST,
};

const GET_SITE = {
  url: BASIC_REST + "/getSite",
  method: GET,
};

/* admin goes here */
const SEARCH_USERS = {
  url: BASIC_ADMIN + "/searchUsers",
  method: GET,
};

const SAVE_USER = {
  url: BASIC_ADMIN + "/saveUser",
  method: POST,
};

const DELETE_USER = {
  url: BASIC_ADMIN + "/deleteUser",
  method: POST,
};

const RESET_USER_PSW = {
  url: BASIC_ADMIN + "/resetPassword",
  method: POST,
};

const SEARCH_ORGANIZATION = {
  url: BASIC_ADMIN + "/searchOrganizations",
  method: GET,
};

const SAVE_ORGANIZATION = {
  url: BASIC_ADMIN + "/saveOrganization",
  method: POST,
};

const DELETE_ORGANIZATION = {
  url: BASIC_ADMIN + "/deleteOrganization",
  method: POST,
};

//prs
const GET_LIST_PRS = {
  url: BASIC_ADMIN + "/getPRSList",
  method: GET,
};

const ADD_NEW_PRS = {
  url: BASIC_ADMIN + "/addPRS",
  method: POST,
};

const GET_CUSTOMER_PRS = {
  url: BASIC_ADMIN + "/getCustomerPrs",
  method: GET,
};

const DELETE_PRS = {
  url: BASIC_ADMIN + "/deletePRS",
  method: POST,
};

const SEND_PAYMENT_EMAIL = {
  url: BASIC_ADMIN + "/sendPaymentEmail",
  method: POST,
};

const PAYMENT_ALREADY_DONE = {
  url: BASIC_ADMIN + "/paymentAlreadyDone",
  method: POST,
};

const DELETE_PAYMENT_REQUEST = {
  url: BASIC_ADMIN + "/deletePaymentRequest",
  method: POST,
};

// extra models
const GET_LIST_EXTRA_MODELS = {
  url: BASIC_ADMIN + "/getExtraModelsList",
  method: GET,
};

const ADD_NEW_EXTRA_MODEL = {
  url: BASIC_ADMIN + "/addExtraModel",
  method: POST,
};

const GET_CUSTOMER_EXTRA_MODELS = {
  url: BASIC_ADMIN + "/getCustomerExtraModels",
  method: GET,
};

const DELETE_EXTRA_MODEL = {
  url: BASIC_ADMIN + "/deleteExtraModel",
  method: POST,
};

//shipping
const GET_LIST_SHIPPING = {
  url: BASIC_ADMIN + "/getShippingList",
  method: GET,
};

const ADD_NEW_SHIPPING = {
  url: BASIC_ADMIN + "/addShipping",
  method: POST,
};

const GET_CUSTOMER_SHIPPING = {
  url: BASIC_ADMIN + "/getCustomerShipping",
  method: GET,
};

const DELETE_SHIPPING = {
  url: BASIC_ADMIN + "/deleteShipping",
  method: POST,
};

//speciment type
const GET_LIST_MANDATORY_FIELD = {
  url: BASIC_ADMIN + "/getMandatoryFieldList",
  method: GET,
};

const ADD_NEW_MANDATORY_FIELD = {
  url: BASIC_ADMIN + "/addMandatoryField",
  method: POST,
};

const DELETE_MANDATORY_FIELD = {
  url: BASIC_ADMIN + "/deleteMandatoryField",
  method: POST,
};

const GET_CUSTOMER_BLOOD_LIST = {
  url: BASIC_ADMIN + "/getCustomerBloodList",
  method: GET,
};

const GET_CUSTOMER_SALIVA_LIST = {
  url: BASIC_ADMIN + "/getCustomerSalivaList",
  method: GET,
};

//REPORT LANGUAGE
const GET_LIST_REPORT_LANGUAGE = {
  url: BASIC_ADMIN + "/getReportLanguageList",
  method: GET,
};

const ADD_NEW_REPORT_LANGUAGE = {
  url: BASIC_ADMIN + "/addReportLanguage",
  method: POST,
};

const GET_CUSTOMER_REPORT_LANGUAGE = {
  url: BASIC_ADMIN + "/getCustomerReportLanguage",
  method: GET,
};

const DELETE_REPORT_LANGUAGE = {
  url: BASIC_ADMIN + "/deleteReportLanguage",
  method: POST,
};

//Tracking
const UPDATE_TRACKING_INFO_CSV = {
  url: BASIC_ADMIN + "/updateTrackingInfoCsv",
  method: POST,
};

const DELETE_SINGLE_TRACKING_INFO = {
  url: BASIC_ADMIN + "/deleteSingleTrackingInfo",
  method: POST,
};

const UPDATE_SINGLE_TRACKING_INFO = {
  url: BASIC_ADMIN + "/updateTrackingInfo",
  method: POST,
};

const GET_ALL_SENT_EMAIL = {
  url: BASIC_ADMIN + "/getAllSentEmail",
  method: GET,
};

const GET_GENERAL_REPORTS_CREATED_DONE_STATISTICS = {
  url: BASIC_ADMIN + "/getGeneralReportsCreatedDoneStatistics",
  method: GET,
};

const GET_ORGANIZATION_FREE_TEST_USAGE = {
  url: BASIC_ADMIN + "/getFreeTestUsage",
  method: GET,
};

export {
  REGISTER_NEW_TEST,
  DELETE_TEST,
  MODIFY_PRS_LIST_TEST,
  SEARCH_REGISTERED_TEST,
  SEARCH_REGISTERED_TEST_ADMIN,
  REGISTER_NEW_TEST_CSV,
  UPLOAD_PATIENT_CONSENT,
  UPLOAD_TEST_PATIENT_REPORT,
  GET_PATIENT_CONSENT,
  GET_PATIENT_REPORT,
  GET_TRACKING_INFO,
  GET_PATIENT_INFO,
  GET_USER_INFO,
  SAVE_USER_CONFIG,
  PATIENT_DETAILS,
  ASSIGN_BARCODE_PATIENT,
  DEALLOCATE_BARCODE_PATIENT,
  INSERT_BARCODE,
  INSERT_MANY_BARCODES,
  DELETE_BARCODE,
  LIST_BARCODES,
  DELETE_PATIENT_CONSENT,
  DELETE_PATIENT_REPORT,
  SEARCH_USERS,
  GET_SITE,
  SAVE_USER,
  DELETE_USER,
  RESET_USER_PSW,
  SEARCH_ORGANIZATION,
  SAVE_ORGANIZATION,
  DELETE_ORGANIZATION,
  ADD_NEW_PRS,
  GET_LIST_PRS,
  GET_CUSTOMER_PRS,
  DELETE_PRS,
  ADD_NEW_EXTRA_MODEL,
  GET_LIST_EXTRA_MODELS,
  GET_CUSTOMER_EXTRA_MODELS,
  DELETE_EXTRA_MODEL,
  GET_LIST_SHIPPING,
  ADD_NEW_SHIPPING,
  GET_CUSTOMER_SHIPPING,
  DELETE_SHIPPING,
  GET_LIST_MANDATORY_FIELD,
  DELETE_MANDATORY_FIELD,
  ADD_NEW_MANDATORY_FIELD,
  GET_CUSTOMER_SALIVA_LIST,
  GET_CUSTOMER_BLOOD_LIST,
  DELETE_REPORT_LANGUAGE,
  ADD_NEW_REPORT_LANGUAGE,
  GET_LIST_REPORT_LANGUAGE,
  GET_CUSTOMER_REPORT_LANGUAGE,
  UPDATE_TRACKING_INFO_CSV,
  DELETE_SINGLE_TRACKING_INFO,
  UPDATE_SINGLE_TRACKING_INFO,
  SEND_PAYMENT_EMAIL,
  GET_ALL_SENT_EMAIL,
  PAYMENT_ALREADY_DONE,
  DELETE_PAYMENT_REQUEST,
  GET_GENERAL_REPORTS_CREATED_DONE_STATISTICS,
  GET_ORGANIZATION_FREE_TEST_USAGE,
};

import React from "react";

const ManageOrganizations = React.lazy(() =>
  import("./pages/ManageOrganizations")
);
const ManageUsers = React.lazy(() => import("./pages/ManageUsers"));
const ManageTests = React.lazy(() => import("./pages/ManageTests"));
const PatientCard = React.lazy(() => import("./pages/Patientcard"));
const ManagePRS = React.lazy(() => import("./pages/ManagePRS"));
const ManageExtraModels = React.lazy(() => import("./pages/ManageExtraModels"));
const ManageShipping = React.lazy(() => import("./pages/ManageShipping"));
const ManageMandatoryFields = React.lazy(() =>
  import("./pages/ManageMandatoryFields")
);
const ManageReportLanguage = React.lazy(() =>
  import("./pages/ManageReportLanguage")
);
const TrackingCSV = React.lazy(() => import("./pages/TrackingCSV"));
const ManageTracking = React.lazy(() => import("./pages/ManageTracking"));
const ManageBarcodes = React.lazy(() => import("./pages/ManageBarcodes"));
const AddManyBarcodes = React.lazy(() => import("./pages/AddManyBarcodes"));
const ManageEmails = React.lazy(() => import("./pages/ManageEmails"));
const ManageStatistics = React.lazy(() => import("./pages/ManageStatistics"));

const routes = [
  { path: "/", exact: true, name: "ManageUsers" },
  {
    path: "/manageOrganizations",
    name: "ManageOrganization",
    element: ManageOrganizations,
  },
  { path: "/ManageUsers", name: "ManageUsers", element: ManageUsers },
  { path: "/ManageTests", name: "ManageTests", element: ManageTests },
  { path: "/patientcard", name: "Patients", element: PatientCard },
  { path: "/managePRS", name: "ManagePRS", element: ManagePRS },
  {
    path: "/manageExtraModels",
    name: "ManageExtraModels",
    element: ManageExtraModels,
  },
  { path: "/manageShipping", name: "ManageShipping", element: ManageShipping },
  {
    path: "/manageMandatoryFields",
    name: "ManageMandatoryFields",
    element: ManageMandatoryFields,
  },
  {
    path: "/manageReportLanguage",
    name: "ManageReportLanguage",
    element: ManageReportLanguage,
  },
  {
    path: "/trackingCSV",
    name: "TrackingCSV",
    element: TrackingCSV,
  },
  {
    path: "/manageTracking",
    name: "ManageTracking",
    element: ManageTracking,
  },
  {
    path: "/manageBarcodes",
    name: "Manage Barcodes",
    element: ManageBarcodes,
  },
  {
    path: "/addManyBarcodes",
    name: "Upload Barcodes",
    element: AddManyBarcodes,
  },
  {
    path: "/manageEmails",
    name: "Manage Emails",
    element: ManageEmails,
  },
  {
    path: "/statistics",
    name: "Statistics",
    element: ManageStatistics,
  },
];

export default routes;

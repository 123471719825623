import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import styled, { useTheme } from "styled-components";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { MenuOutlined } from "@mui/icons-material";
import NewsComponent from "./components/NewsComponent";
import SidebarComponent from "./components/SidebarComponent";

const useLayoutFunctions = () => {
  const DRAWER_WIDTH_OPEN = 280;
  const DRAWER_WIDTH_CLOSE = 70;

  const useStyles = makeStyles((theme) => ({
    sidebar: {
      width: 300,
      background: theme.palette.primary.main,
      height: "100%",
      position: "fixed",
    },
    avatar: {
      margin: "0.5rem auto",
      padding: "1rem",
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
    label: {
      color: "white",
      textAlign: "left",
    },
    logo: {
      display: "block",
      maxWidth: "80%",
      maxHeight: "100%",
      margin: "auto",
      marginTop: "30px",
    },
    link: {
      textDecoration: "none",
      color: "white",
      textColor: "white",
    },
  }));

  const correctRouter = (selectedUser) => {
    return <Route path="/" element={<Navigate to="manageUsers" replace />} />;
  };

  return {
    DRAWER_WIDTH_OPEN,
    DRAWER_WIDTH_CLOSE,
    correctRouter,
    useStyles,
  };
};

export default useLayoutFunctions;

/* eslint-disable react/prop-types */
//import useState hook to create menu collapse state
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as Actions from "../../store/Actions/localTokenAction";

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Grid,
  Dialog,
  DialogTitle,
  Alert,
  DialogContent,
  DialogContentText,
  Stack,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  PlaylistAdd,
  AirportShuttle,
  Settings,
  Logout,
  Assignment,
  StackedBarChart,
  Calculate,
  DriveFolderUpload,
  People,
  Vaccines,
  Analytics,
  LockReset,
  PeopleAlt,
  CorporateFare,
  Biotech,
  LocalShipping,
  TextFields,
  Flag,
  ViewColumn,
  TableChart,
  Schema,
  Email,
  PieChartOutline,
} from "@mui/icons-material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    background: theme.palette.primary.main,
    height: "100%",
    position: "fixed",
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
  label: {
    color: "white",
    textAlign: "left",
  },
  logo: {
    display: "block",
    maxWidth: "80%",
    maxHeight: "100%",
    margin: "auto",
    marginTop: "30px",
  },
  link: {
    textDecoration: "none",
    color: "white",
    textColor: "white",
  },
}));

const SidebarComponent = (props) => {
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [openChangePasswordPopup, setChangePasswordPopup] = useState(false);

  const classes = useStyles();
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    };
  });

  const is_not_local_installation = () => {
    //console.log(process.env.REACT_APP_LOCAL_INSTALLATION === "YES");
    return !(process.env.REACT_APP_LOCAL_INSTALLATION === "YES");
  };

  const sidebarMenus = () => {
    let listItems = [];
    //console.log("user is ", user);
    if (user) {
      if (user.hasOwnProperty("admin")) {
        let admin = user.admin;

        if (admin) {
          listItems.push({
            listIcon: <PeopleAlt style={{ color: "white" }} />,
            listText: "Manage Users",
            path: "/manageUsers",
          });
          listItems.push({
            listIcon: <CorporateFare style={{ color: "white" }} />,
            listText: "Manage Organizations",
            path: "/manageOrganizations",
          });
          listItems.push({
            listIcon: <Biotech style={{ color: "white" }} />,
            listText: "Manage Tests",
            path: "/manageTests",
          });
          if (is_not_local_installation()) {
            listItems.push({
              listIcon: <Email style={{ color: "white" }} />,
              listText: "Manage Emails",
              path: "/manageEmails",
            });
            listItems.push({
              listIcon: <PieChartOutline style={{ color: "white" }} />,
              listText: "Statistics",
              path: "/statistics",
            });
          }
          listItems.push({
            listIcon: <ViewColumn style={{ color: "white" }} />,
            listText: "Loading Barcodes",
            path: "/manageBarcodes",
          });
          listItems.push({
            listIcon: <TableChart style={{ color: "white" }} />,
            listText: "Load CSV Barcodes",
            path: "/addManyBarcodes",
          });
          if (is_not_local_installation()) {
            listItems.push({
              listIcon: <LocalShipping style={{ color: "white" }} />,
              listText: "Load Tracking CSV",
              path: "/trackingCSV",
            });
            listItems.push({
              listIcon: <LocalShipping style={{ color: "white" }} />,
              listText: "Manage Tracking",
              path: "/manageTracking",
            });

            listItems.push({
              listIcon: <Analytics style={{ color: "white" }} />,
              listText: "Manage PRS",
              path: "/managePRS",
            });

            listItems.push({
              listIcon: <Schema style={{ color: "white" }} />,
              listText: "Manage Extra Models",
              path: "/manageExtraModels",
            });

            listItems.push({
              listIcon: <Assignment style={{ color: "white" }} />,
              listText: "Manage Mandatory Fields",
              path: "/manageMandatoryFields",
            });
            listItems.push({
              listIcon: <Flag style={{ color: "white" }} />,
              listText: "Manage Report Laguage",
              path: "/manageReportLanguage",
            });
            listItems.push({
              listIcon: <LocalShipping style={{ color: "white" }} />,
              listText: "Manage Shipping",
              path: "/manageShipping",
            });
          }
        }
      }
      //if the user has no permissions
      if (listItems.length === 0) {
        setOpenErrorPopup(true);
        setTimeout(() => {
          //close the popup
          setOpenErrorPopup(false);
          //redirect to logout
          process.env.REACT_APP_LOCAL_INSTALLATION === "YES"
            ? local_logout()
            : logout({ returnTo: window.location.origin });
        }, 4000);
      }
    }
    return listItems;
  };

  const AccountSettings = () => {
    return (
      <ListItem className={classes.link} button>
        <ListItemIcon>
          <Settings className={classes.link} />
        </ListItemIcon>
        <Link to="settings" className={classes.link}>
          Account settings
        </Link>
      </ListItem>
    );
  };

  const ResetPasswordButton = () => {
    return (
      <ListItem className={classes.link} button>
        <ListItemIcon>
          <LockReset className={classes.link} />
        </ListItemIcon>
        <Link
          className={classes.link}
          onClick={() => setChangePasswordPopup(true)}
        >
          Change Password
        </Link>
      </ListItem>
    );
  };

  const local_logout = () => {
    dispatch(Actions.setLocalToken(null));
    // Get the current URL
    const currentURL = window.location.href;

    // Get the base URL without the path
    const baseURL = currentURL.split("/").slice(0, 3).join("/");

    // Redirect to the base URL
    window.location.href = baseURL;
  };

  return (
    <>
      <Box className={classes.sidebar} component="div" height={"100vh"}>
        <Grid container directon={"column"} justifyContent={"space-between"}>
          <Grid item alignItems={"center"}>
            <List>
              {sidebarMenus().map((listItem, index) =>
                props.open ? (
                  <ListItem
                    className={classes.link}
                    button
                    key={index}
                    to={listItem.path}
                    component={Link}
                    style={{ color: "white" }}
                  >
                    <ListItemIcon sx={{ minWidth: 56 }}>
                      {listItem.listIcon}
                    </ListItemIcon>
                    {listItem.listText}
                  </ListItem>
                ) : (
                  <Tooltip
                    title={listItem.listText}
                    key={index}
                    placement="right"
                  >
                    <ListItem
                      className={classes.link}
                      button
                      key={index}
                      to={listItem.path}
                      component={Link}
                      style={{ color: "white" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        {listItem.listIcon}
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )
              )}

              {user ? (
                props.open ? (
                  <ListItem
                    className={classes.link}
                    button
                    component={Link}
                    style={{ color: "white" }}
                    onClick={() =>
                      process.env.REACT_APP_LOCAL_INSTALLATION === "YES"
                        ? local_logout()
                        : logout({ returnTo: window.location.origin })
                    }
                  >
                    <ListItemIcon sx={{ minWidth: 56 }}>
                      <Logout className={classes.link} />
                    </ListItemIcon>
                    {props.open ? "LOG OUT" : ""}
                  </ListItem>
                ) : (
                  <Tooltip title={"Log out"} placement="right">
                    <ListItem
                      className={classes.link}
                      button
                      component={Link}
                      style={{ color: "white" }}
                      onClick={() =>
                        process.env.REACT_APP_LOCAL_INSTALLATION === "YES"
                          ? local_logout()
                          : logout({ returnTo: window.location.origin })
                      }
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Logout className={classes.link} />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )
              ) : (
                <div />
              )}
            </List>
            {/*</Collapse>*/}
          </Grid>
        </Grid>
      </Box>
      <Dialog open={openErrorPopup}>
        <DialogTitle>
          <Alert severity="error">Access Error</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            User has NO PERMISSIONS to access this platform.
            <br />
            Redirect to Logout.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SidebarComponent;

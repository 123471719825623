// useAxios.js
import { useState, useMemo } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../store/Actions/localTokenAction";

const TIMEOUT_MINUTES = 30;
//timeout is in milliseconds
const TIMEOUT_VALUE = TIMEOUT_MINUTES * 60 * 1000;

const axiosInstance = axios.create({
  baseURL: "/api",
  timeout: TIMEOUT_VALUE,
  maxContentLength: 100000000,
  maxBodyLength: 1000000000,
});

const axiosController = new AbortController();

function fetcher(url) {
  return axiosInstance.get(url).then((res) => res.data);
}

function setAuthorization(token) {
  axiosInstance.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : "";
}

function setContentEncoding(value) {
  axiosInstance.defaults.headers.common["Content-Encoding"] = value;
}

const useAxiosConfig = () => {
  //const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [token, setToken] = useState(null);

  const { getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useDispatch();

  const { local_token } = useSelector((state) => {
    return {
      local_token: state.token.local_token,
    };
  });

  const local_logout = () => {
    dispatch(Actions.setLocalToken(null));
  };

  async function handleSuccessRequest(config) {
    console.log("axios config ", local_token);
    setPendingRequests(pendingRequests + 1);
    if (axiosController.signal) config.signal = axiosController.signal;

    //retrieve the token
    let retrieved_token = null;
    if (process.env.REACT_APP_LOCAL_INSTALLATION === "YES") {
      retrieved_token = local_token;
    } else {
      retrieved_token = await getAccessTokenSilently({
        scopes: ["openid", "profile", "email"],
      });
    }

    config.headers.Authorization = retrieved_token
      ? `Bearer ${retrieved_token}`
      : "";

    //console.log("handleSuccessRequest decodedJWT", retrieved_token);

    setAuthorization(retrieved_token);
    setToken(retrieved_token);

    setTimeoutFunction(retrieved_token);

    setContentEncoding("gzip");
    setIsLoading(true);
    return config;
  }

  //here we set a timeout at every call. when the token expires, the redirect to login
  const setTimeoutFunction = async (retrieved_token) => {
    if (retrieved_token) {
      const decodedJwt = parseJwt(retrieved_token);

      //console.log("setTimeoutFunction decodedJWT", decodedJwt);
      const expire_time = decodedJwt.exp * 1000;
      const now_time = Date.now();

      //so, if if the token has exprired we go under zero. hence the timer will redirect to logout
      const value_over_zero = Math.max(0, expire_time - now_time);
      //max 5 minutes of inactivity
      const timerValue = Math.min(value_over_zero, TIMEOUT_VALUE);

      /*console.log(
      "here are the exprires values of the token .",
      expire_time,
      now_time,
      expire_time - now_time,
      timerValue
    );*/

      let timeoutId = setTimeout(() => {
        console.log("Calling the logout. The session is expired");
        process.env.REACT_APP_LOCAL_INSTALLATION === "YES"
          ? local_logout()
          : logout();
      }, timerValue);

      //console.log("timer id is now ", timeoutId);

      //delete all previous timeouts
      while (timeoutId--) {
        clearTimeout(timeoutId); // will do nothing if no timeout with id is present
      }
    }
  };

  const renewTimeout = () => {
    const timerValue = TIMEOUT_VALUE;
    let timeoutId = setTimeout(() => {
      console.log("Calling the logout. The session is expired");
      process.env.REACT_APP_LOCAL_INSTALLATION === "YES"
        ? local_logout()
        : logout();
    }, timerValue);
    //console.log("timer id is now ", timeoutId);
    //delete all previous timeouts
    while (timeoutId--) {
      clearTimeout(timeoutId); // will do nothing if no timeout with id is present
    }
  };

  function handleErrorRequest(error) {
    return Promise.reject(error);
  }

  function handleSuccessResponse(response) {
    setIsLoading(false);
    return response;
  }

  function handleErrorResponse(error) {
    setIsLoading(false);
    setPendingRequests(pendingRequests - 1);
    if (axios.isAxiosError(error)) {
      if (error.response) {
        /*notify({
          severity: "error",
          summary: error.name,
          detail: error.message + "-" + error.response?.data.message,
        });*/
        //console.log("This is the error branch");
        //throw new Error(error.response.data.message);
        return error.response;
      }
    } else {
      /*notify({
        severity: "error",
        summary: t("notifyErrors.generic"),
      });*/
      //throw new Error("Unexpected error!");
      //console.log("this is the warning branch");
      //throw new Error(error.message);
    }
  }
  useMemo(
    () =>
      axiosInstance.interceptors.request.use(
        handleSuccessRequest,
        handleErrorRequest
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useMemo(
    () =>
      axiosInstance.interceptors.response.use(
        handleSuccessResponse,
        handleErrorResponse
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  return {
    axiosInstance,
    axiosController,
    fetcher,
    isLoading,
    setAuthorization,
    renewTimeout,
  };
};

export default useAxiosConfig;
